import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap'
import {
   Card,
   CardBody,
   CardHeader,
   CardTitle,
   CardFooter,
   Button,
   CustomInput
} from 'reactstrap';
import { addStore, editStore, zalora_getShipmentProvider2 } from 'services/dataservice';

export function AddStoreZalora2(props){
    const [apiKey, setApiKey] = useState("");
   const [apiSecret, setApiSecret] = useState("");
   const [defaultShippingProvider, setDefaultShippingProvider] = useState("");
   const [shippingProviderList, setDefaultShippingProviderList] = useState([]);
   const [userMarketplaceId, setUserMarketplaceId] = useState(0);
   const [integratedShipping, setIntegratedShipping] = useState(true);
   const [syncInventory, setSyncInventory] = useState(true);
   const [userMarketplaceName, setUserMarketplaceName] = useState("")
   const[clientId,setClientId]=useState("")
   const[clientSecret,setClientSecret]=useState("")

   const history = useHistory();

   useEffect(() => {
      if (props.edit) {
         const u = props.selectedMarketplace;

         setUserMarketplaceName(u.storeName);
         setUserMarketplaceId(u.userMarketplaceId);
         setApiKey(u.apiKey);
         if(!u.secretKey.includes("SecretKey")){
            setApiSecret(u.secretKey)
         }else{
            const secretKey=JSON.parse(u.secretKey)
         setApiSecret(secretKey.SecretKey)
         setClientId(secretKey.ClientId)
         setClientSecret(secretKey.ClientSecret)
         }
         // if (u.secretKey.includes("client_secret")){
         //    var secret = JSON.parse(u.secretKey);
         //    setApiSecret(secret.client_secret);
         // } else {
         //    setApiSecret(u.secretKey);
         // }
         setDefaultShippingProvider(u.shippingProvider);
         setIntegratedShipping(u.integrated);
         setSyncInventory(u.syncInventory);


         setDefaultShippingProviderList([{ name: u.shippingProvider }]);
      }

   }, [props.selectedMarketplace])

   const onSubmit = async () => {
      try {
         if (props.edit && (defaultShippingProvider === "" || shippingProviderList.length === 0)) {
            props.showFail("Please select default shipping provider");
            return;
         }
         const secretKey=JSON.stringify({
            clientId,
            clientSecret,
            secretKey:apiSecret
         })

         if (props.edit) {
            const resp = await editStore({
               userMarketplaceId,
               storeName: userMarketplaceName,
               marketplaceId: props.selectedMarketplace.marketplaceId,
               apiKey,
               secretKey,
               active: true,
               integrated: integratedShipping,
               shippingProvider: defaultShippingProvider,
               syncInventory
            })

         }
         else {
            
            const resp = await addStore(
               {
                  storeName: userMarketplaceName,
                  marketplaceId: props.selectedMarketplace.marketplaceId,
                  apiKey,
                  secretKey,
                  shippingProvider: defaultShippingProvider,
                  syncInventory
               }
            );
         }


         history.push("/admin/integration/list");
      }
      catch (err) {
         const errResp = err.response;
         if (errResp.status === 400) {
            if (errResp.data.error === "duplicated_store_name") {
               props.showFail("Duplicated Store Name");
            }

         }
      }
   };

   const getProviderInfo = () => {
      const secretKey=JSON.stringify({
         clientId,
         clientSecret,
         secretKey:apiSecret
      })
      let req = {
         userMarketplaceId,
         storeName: userMarketplaceName,
         marketplaceId: props.selectedMarketplace.marketplaceId,
         apiKey,
         secretKey,
         shippingProvider: defaultShippingProvider
      }

      zalora_getShipmentProvider2(req).then(x => {
         setDefaultShippingProviderList(x.data);
         setDefaultShippingProvider(x.data[0].name)
      });
   }

   const onChangeIntegrated = (e) => {
      setIntegratedShipping(e.target.checked);
   }


   return (
      <Card>
         <CardHeader>
            <CardTitle tag="h4">Selected store: {props.selectedMarketplace.name}</CardTitle>
         </CardHeader>
         <CardBody>
            <Form>
               <FormGroup>
                  <label>Name</label>
                  <Input type="text" value={userMarketplaceName} onChange={(e) => setUserMarketplaceName(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>User ID</label>
                  <Input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)} disabled={props.edit===true}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Secret Key</label>
                  <Input type="text" value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} disabled={props.edit===true}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Client ID</label>
                  <Input type="text" value={clientId} onChange={(e) => setClientId(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Client Secret</label>
                  <Input type="text" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Shipping</label>
                  <CustomInput type="switch" id="idIntegrated" name="integrated" label="Integrated Shipping" onChange={onChangeIntegrated} checked={integratedShipping} />
               </FormGroup>
               <FormGroup>
                  <label>Default Shipping Provider</label>
                  <Input type="select" onChange={(e) => setDefaultShippingProvider(e.target.value)}>
                     {
                        shippingProviderList.map(x => (
                           <option key={x.name} value={x.name}>{x.name}</option>
                        ))
                     }
                  </Input>
                  <Button color="primary" onClick={getProviderInfo} disabled={clientId.length===0||clientSecret.length===0}>Get</Button>
               </FormGroup>
               <FormGroup>
                  <label>Sync Inventory</label>
                  <CustomInput type="switch" id="idSyncInventory" name="syncInventory" label="Sync Inventory" onChange={(e) => setSyncInventory(e.target.checked)} checked={syncInventory} />
               </FormGroup>
            </Form>
         </CardBody>
         <CardFooter>
            <Button color="primary" onClick={onSubmit} >Submit</Button>
         </CardFooter>
      </Card>

   )
}